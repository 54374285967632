.timeframe-selector {
	padding: 5px;
	cursor: pointer;
	margin-right: 5px;
	border: 1px solid #000;
}
.timeframe-selector:last-of-type {
	margin-right: 0px;
}
.timeframe-selector:hover {
	background-color: rgba( 0, 0, 0, 0.3);
	color: #fff;
}
.timeframe-selector.active {
	background-color: rgba( 0, 0, 0, 0.7);
	color: #fff;
}

.event-list div {
	padding: 2px 0px;
}
.event-list .list-item:nth-child(2n) {
	background: #8080804d;
}